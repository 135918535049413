<template>
  <div :class="{ disabled }" @click="launchFilePicker">
    <slot>
      <div class="form-container form-uploader">
        <label>{{ label }}</label>
        <button class="btn btn-primary btn-block">Subir {{ label }}</button>
      </div>
    </slot>
    <input type="file" @change="fileSelected" ref="fileInput" />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Object, File, String],
      default: () => ({}),
    },
    label: {
      type: String,
      default: "File",
    },
    maxSize: {
      type: Number,
      default() {
        return 1024 * 200; // 2 MB
      },
    },
    pdfType: {
      type: Boolean,
      default: false,
    },
    imageType: {
      type: Boolean,
      default: false,
    },
    videoType: {
      type: Boolean,
      default: false,
    },
    csvType: {
      type: Boolean,
      default: false,
    },
    xlsxType: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooBigErrorMsg: {
      type: String,
      default: "El archivo no puede pesar mas de {maxSize}MB",
    },
  },
  computed: {
    tooBigMsg() {
      if (this.tooBigErrorMsg.includes("{maxSize}")) {
        return this.tooBigErrorMsg.replace(
          "{maxSize}",
          (this.maxSize / 1024).toFixed(2)
        );
      }
      return this.tooBigErrorMsg;
    },
    acceptedType() {
      const acceptedType = []
      if (this.videoType) {
        acceptedType.push('video')
      }
      if (this.imageType) {
        acceptedType.push('image')
      }
      if (this.pdfType) {
        acceptedType.push('pdf')
      }
      if (this.csvType) {
        acceptedType.push('csv')
      }
      if (this.xlsxType) {
        acceptedType.push('xlsx')
      }
      return acceptedType;
    },
  },

  // File upload only supports the following filetypes - /pdf|jpg|jpeg|mp4|png|csv|xlsx|docx/

  methods: {
    launchFilePicker() {
      if (this.disabled) return;
      this.$refs.fileInput.click();
    },
    fileSelected(evt) {
      // const fieldName = evt.target.name;
      const file = evt.target.files;
      const { maxSize } = this;

      // check if user actually selected a file
      if (file.length > 0) {
        const uploadedFile = file[0];
        const size = uploadedFile.size / maxSize / maxSize;
        // Check file type
        if (this.acceptedType.length) {
          if (
            this.acceptedType.includes("image") &&
            !uploadedFile.type.match("image.*")
          ) {
            // check whether the upload is an image
            this.$toast.error("El archivo no es una imagen");
            return;
          }
          if (
            this.acceptedType.includes("video") &&
            !uploadedFile.type.match("video.*")
          ) {
            // check whether the upload is an video
            this.$toast.error("El archivo no es un video");
            return;
          }
          if (
            this.acceptedType.includes("pdf") &&
            !uploadedFile.type.match("application/pdf")
          ) {
            // check whether the upload is an Pdf
            this.$toast.error("El archivo no es un PDF");
            return;
          }
          if (
            this.acceptedType.includes("csv") &&
            !uploadedFile.type.match("text/csv")
          ) {
            // check whether the upload is an CSV
            this.$toast.error("El archivo no es un CSV");
            return;
          }
        } else if (
          !uploadedFile.type.match("video.*") &&
          !uploadedFile.type.match("image.*") &&
          !uploadedFile.type.match("text/csv") &&
            !uploadedFile.type.match("application/pdf") &&
          !uploadedFile.type.match("application/wps-office.xlsx") &&
          !uploadedFile.type.match("application/vnd.ms-excel") &&
          !uploadedFile.type.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
        ) {
          // check whether the upload is an image
          this.$toast.error(uploadedFile.type);
          this.$toast.error("El archivo no es un PDF/CSV o una Imagen o un Video");
          return;
        }

        // check whether the size is greater than the size limit
        if (size > 1) {
          this.$toast.error(this.tooBigMsg);
        } else {
          this.$emit("update:modelValue", uploadedFile);
          this.$emit("onChange", {
            file: uploadedFile,
            url: URL.createObjectURL(uploadedFile),
            evt
          });
        }
      }
    },
  },
};
</script>

<style scoped>
input {
  display: none;
}
</style>
